export const DIMENSION = {
  Desktop: 'desktop',
  Tablet: 'tablet',
  Mobile: 'mobile',
};

export const LAYOUT = {
  Fluid: 'fluid',
  Boxed: 'boxed',
};

export const RADIUS = {
  Rounded: 'rounded',
  Standard: 'standard',
  Flat: 'flat',
};

export const THEME_COLOR = {
  LightBlue: 'light-blue',
  DarkBlue: 'dark-blue',
  LightRed: 'light-red',
  DarkRed: 'dark-red',
  LightGreen: 'light-green',
  DarkGreen: 'dark-green',
  LightPurple: 'light-purple',
  DarkPurple: 'dark-purple',
  LightPink: 'light-pink',
  DarkPink: 'dark-pink',
};

export const NAV_COLOR = {
  Default: 'default',
  Light: 'light',
  Dark: 'dark',
};
export const MENU_PLACEMENT = {
  Vertical: 'vertical',
  Horizontal: 'horizontal',
};
export const MENU_BEHAVIOUR = {
  Pinned: 'pinned',
  Unpinned: 'unpinned',
};

export const USER_ROLE = {
  Admin: 'superAdmin',
  Editor: 'editor',
};

// export const apiUrl = `http://localhost:8480/api/v1/`
export const apiUrl = `https://be.contentforge.teceads.co.in/api/v1/`
export const vgApiUrl = `https://vg.contentforge.teceads.co.in/`
// export const apiUrl = `http://192.168.1.29:8005/api/v1/`


// export const apiUrl=`http://localhost:5011/api/v1/`
export const staticFileUrl = `http://localhost:8005/`;
// export const apiUrl = `http://192.168.1.14:8005/api/v1/`

export const apiSecret = `registeruser`;

export const getToken = async () => {
  let token = await localStorage.getItem('data');
  token = token ? JSON.parse(token).token : '';
  return `${token}`;
};
export const getRole = async () => {
  let role = await localStorage.getItem('data');
  role = role ? JSON.parse(role).role : '';
  return `${role}`;
};
export const getBasicUserDetails = async () => {
  let role = await localStorage.getItem('data');
  role = role ? JSON.parse(role) : {};
  console.log(role, 'role1');

  if (role && role != '' && role != {}) {
    role = role.userBasicDetails;
    console.log(role, 'role2');
  }
  return role;
};
